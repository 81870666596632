import abi from "./contract/GlobalPool.json";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import Buy from "./components/Buy";
import Buy1 from "./components/buy1";
import Buy2 from "./components/buy2";
import Buy3 from "./components/buy3";
import Memos from "./components/Memos";
import Task  from "./tasks";
import "./style.css";
import "./bigvisionz.png";
import "./copy.png";

import { red } from "@mui/material/colors";


function App() {
  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[level,setLevel]=useState("None");
  const[auto,setAuto]=useState("None");
  const[bonus,setBonus]=useState("None");
  const[pack,setpackage]=useState("None");
  const[refer,setrefer]=useState("None");
const [tasks,setTasks] = useState([]);
const [total,setTotal]=useState("None");
const [req,setreq]=useState("None");
const [tt,settt]=useState("None");
const[dd,setDD]=useState("None");
const[referlink,setreflink]=useState("None");
const[bonanza,setBonanza]=useState("None");
const[upd,setupd]=useState("None");


  useEffect(() => {
    
    const connectWallet = async () => {
      
      const contractAddress = "0x4ad99f6461828ff8d9c3eba26c3ee2fae63ec1d1";
      const contractABI = abi.abi;
     
      const searchParams = new URLSearchParams(window.location.search);
    const myParam = searchParams.get('ref');
    console.log(myParam);
    document.querySelector("#name").value=myParam;
if(myParam == null)
  {
    document.querySelector("#name").value="0x67b3596E5326500FBa8d7D8ABb542e2a598FbD4b";
  }
      try {
        const { ethereum } = window;
        document.querySelector("#test").value = "Update For USDT 20";
        if (ethereum) {
          const account = await ethereum.request({
            method: "eth_requestAccounts",
           
          });
         
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });

          window.ethereum.on("accountsChanged", () => {
            
            window.location.reload();
          });

          const provider = new ethers.providers.Web3Provider(ethereum);
       /*   try {
  
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x38' }],    // chainId must be in HEX with 0x in front
              
            });
            console.log("You have switched to the right network")
            
          } catch (switchError) {
            
            // The network has not been added to MetaMask
            if (switchError.code === 4902) {
             console.log("Please add the Binance network to MetaMask")
            }
            console.log("Cannot switch to the network")
            
          }
*/
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );
          setAccount(account);
          setState({ provider, signer, contract });
          document.querySelector("#us").value  = account[0];
      let alltasks = await contract.getMemos(account[0]);
      setTasks(alltasks);
      const b =Number(alltasks[2]);
      const refer = (alltasks[1]);
     setrefer(refer);
     document.querySelector("#hid").value  = (alltasks[1]);
     const direct =Number(alltasks[2]);
     setDirect(direct);
     const directs = 5 * Number(alltasks[2]);
     setDirects(directs);
     const level = Number(alltasks[3]);
     setLevel(level);
     const auto = Number(alltasks[4]);
     setAuto(auto);
     const bonus = Number(alltasks[5]);
     setBonus(bonus);
    
     const upd = Number(alltasks[10]);
     setupd(upd);
     const tt = (5 * Number(alltasks[2])) + level + bonus + auto + upd;
     settt(tt);
     const pack = Number(alltasks[6]);
     document.querySelector("#pp").value  = Number(alltasks[6]);
     

setpackage(pack);
const bonanza = Number(alltasks[7]);
     setBonanza(bonanza);
const dd = new Date(alltasks[8].timestamp * 1000).toLocaleString();
setDD(dd);

     setB(b);
     const referlink = 'https://bigvisionz.com/?ref='+ account;
     setreflink(referlink);
     document.querySelector("#link").value =referlink;
if(pack==20)
{ 
  document.querySelector("#test").value = "Update For USDT 40";

}
else if(pack==60)
{ 
  document.querySelector("#test").value = "Update For USDT 80";

}
else if(pack ==140)
{
  document.querySelector("#test").value = "Update For USDT 160";
}
else if(pack ==300)
{
  document.querySelector("#test").value = "Update For USDT 320";
}
else if(pack ==620)
{
  document.querySelector("#test").value = "Update For USDT 640";
}
else if(pack ==1260)
{
  document.querySelector("#test").value = "Update For USDT 1280";
}
else if(pack ==2540)
{
  document.querySelector("#test").value = "Update For USDT 2560";
}
else if(pack ==5100)
{
  document.querySelector("#test").value = "Bravo All Packages Purchased";
}

console.log(pack);
     
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
   
    
    connectWallet();
    
    function myFunction() {
      
      var copyText = "hello";
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      
    
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.valueOf);
    }

  }, []);

 
  // console.log(state);
  return (
  <div>
    <input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>
<div class="se-pre-con"></div>
    <div class="wrapper"  style={{
                      backgroundColor: "#000000",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
       
        <nav id="sidebar" style={{
                      backgroundColor: "#000000"}}>
            <div class="sidebar-header" >
                <h1>
                    <a ><img src ="bigvisionz.png" width="25%" height="25%"></img></a>
                </h1>
              
            </div>
            <div>
          
            </div> </nav></div>
            <div id="content">
            
      <div className="container">
       
   
</div></div>
<div class="container-fluid">
                <div class="row">
                   
                    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">Wallet Address</p>
                            </div>
                            Connected Account - {account}
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">My Refer</p>
                            </div>
                           {refer}
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">My Refer Link</p>
                            </div>
                           {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
         navigator.clipboard.writeText(referlink);}} /></button>
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">My Total Package</p>
                            </div>
                            USDT {pack}
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">My Direct Income</p>
                            </div>
                            USDT {directs}
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">My Direct Team</p>
                            </div>
                             {direct}
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">My Level Income</p>
                            </div>
                            USDT {level}
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">My Upgrade Income</p>
                            </div>
                            USDT {upd}
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">My AutoPool Bonus</p>
                            </div>
                            USDT {auto}
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">My Bonanza Bonus</p>
                            </div>
                            USDT {bonus}
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">My Total Bonus</p>
                            </div>
                            USDT {tt}
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">Total Bonanza Purchased</p>
                            </div>
                            USDT {bonanza}
                        </div>
    </div>
  
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">Wallet Address</p>
                            </div>
                            Connected Account - {account}
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">Purchase AutoPool</p>
                            </div>
                            <input type ="text" id ="test" readonly="readonly"></input>
                            <Buy state={state} />
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">Purchase Bonanza - I</p>
                            </div>
                            
                            <Buy1 state={state} />
                        </div>
    </div>
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#96D4D4",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                
                                <p class="paragraph-agileits-w3layouts text-white">Purchase Bonanza - II</p>
                            </div>
                            
                            <Buy2 state={state} />

                        </div>

                       
    </div>
  
  
    </div></div>
  
    </div>
  
  
  );
}

export default App;